export function changeState(){
  return {
    type:'@menu/CHANGE_STATE',
		payload: {}
  };
}

export function cartChangeState(){
  return {
    type:'@cart/CHANGE_STATE',
    payload: {}
  };
}

export function menuChangeState(){
  return {
    type:'@auth/CHANGE_STATE_SUCCESS',
    payload: {}
  };
}

