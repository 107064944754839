import React, { useState } from "react";
import {
	Container,
	// Logo,
	Title,
	Loader,
	LoadSVG,
	//Icon,
	Button,
	SubTitle,
	BoxInfo,
	TitleInfo,
	ErroInfo,
	//Close,
	//ErroInfoSub,
} from "./styles";
import Loading from "../../assets/error.svg";
// import logo_img from "../../assets/error.png";
// import { BiError } from "react-icons/bi";

const ErrorComponent = ({ error, rest, info }) => {
	const [displayInfo, setDisplayInfo] = useState(false);
	return (
		<Container>
			{/* {displayInfo ? (
				<Close error={displayInfo} onClick={() => setDisplayInfo(!true)} />
			) : (
				error.message && (
					<Icon error={displayInfo} onClick={() => setDisplayInfo(!false)} />
				)
			)} */}
			<BoxInfo display={displayInfo}>
				<TitleInfo>Detalhe do Erro</TitleInfo>
				<ErroInfo>
					{error.message || "Não foi possível coletar o erro"}
				</ErroInfo>
				{/* <ErroInfoSub>{info.componentStack || ""}</ErroInfoSub> */}
			</BoxInfo>
			<Loader>
				{/* <Logo src={logo_img} /> */}
				<LoadSVG type="image/svg+xml" data={Loading}>
					<img src={Loading} alt="loading" />
				</LoadSVG>
				<Title>Ops! Algo deu errado.</Title>
				<SubTitle>Informe o ocorrido aos desenvolvedores.</SubTitle>
				{/* <SubTitle>O problema já foi enviado para os desenvolvedores.</SubTitle> */}
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						marginTop: "20px",
					}}
				>
					<Button onClick={() => rest()}> Tente novamente </Button>
					<Button
						outline
						onClick={() =>
							(window.location.href = process.env.REACT_APP_API_FRONT)
						}
					>
						{" "}
						Voltar a home{" "}
					</Button>
				</div>
			</Loader>
		</Container>
	);
};

export default ErrorComponent;
