import React, { lazy, Suspense } from "react";

import './App.css'

import { BrowserRouter } from "react-router-dom";
import GlobalStyles from "./styles/global";
import "react-toastify/dist/ReactToastify.css";
import Load from "./components/SplashScreen";
import ErrorComponent from "./components/ErrorComponent";
import { ErrorBoundary } from "react-error-boundary";
const Routes = lazy(() => import("./routes"));

function ErrorFallback({ error, resetErrorBoundary }) {
	return (
		<ErrorComponent error={error} rest={resetErrorBoundary} info={false} />
	);
}
function App() {
	return (
		<BrowserRouter>
			<GlobalStyles />
			<Suspense fallback={<Load />}>
				{process.env.REACT_APP_ENV === "PROD" ? (
					<ErrorBoundary FallbackComponent={ErrorFallback}>
						<Routes />
					</ErrorBoundary>
				) : (
					<Routes />
				)}
			</Suspense>
		</BrowserRouter>
	);
}

export default App;
