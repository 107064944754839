const translate = {
	translation:{
		"RESOURCES": "Resources",
		"January":"January",
		"February":"February",
		"March":"March",
		"April":"April",
		"May":"May",
		"June":"June",
		"July":"July",
		"August":"August",
		"September":"September",
		"October":"October",
		"November":"November",
		"December":"December",
		"NEW":"New",
		"REFRESH": "Refresh",
		"EXIT": "Exit",
		"MYPROFILE": "My profile",
		"PAYMENTS": "Payments",
		"INVESTMENTS": "Investments",
		"RESULTS": "Results",
		"CONTACT": "Contact",
		"VALUE": "Value",
		"ACTIONS": "Actions",
		"SUBJECT": "Subject",
		"MESSAGE": "Message",
		"SUBMIT": "Submit",
		"ROWSPERPAGE": "Rows per page:",
		"SEPARATOR": "of",
		"ALL": "All",
		"NODATA": "No records found",
		"FILTERBYVALUE": "Filter by value",
	}
}
export default translate;