export default function compare(attr){
	return function(a, b){
		if ( a[attr] < b[attr] ){
			return -1;
		}
		if ( a[attr] > b[attr] ){
			return 1;
		}
		return 0;
	}
}