import { BiErrorCircle } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import styled from "styled-components";
import { Utils } from "../../config/Utils";

export const Container = styled.div`
	display: flex;
	background-color: rgb(241, 242, 243);
`;

export const Loader = styled.div`
	display: flex;
	width: 100vw;
	height: 100vh;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const LoadSVG = styled.object`
	width: 500px;
	justify-content: center;
	background-color: transparent;
	user-select: none;
`;

export const Logo = styled.img`
	/* justify-content: center; */
	width: 500px;
	user-select: none;
	background-color: transparent;
	/* transition: opacity 0.3s, visibility 0.3s; */
	/* animation: fadeinout 1s infinite;
	@keyframes fadeinout {
		0% {
			opacity: 30%;
		}
		100% {
			opacity: 100%;
		}
	} */
`;

export const Title = styled.h2`
	font-size: 25px;
	text-align: center;
	color: ${Utils.COLORS.RED};
`;

export const SubTitle = styled.span`
	padding: 3px;
	text-align: center;
	color: #545454;
`;

export const Icon = styled(BiErrorCircle)`
	width: ${(props) => props.width || "21px"};
	height: ${(props) => props.height || "21px"};
	color: ${(props) => (props.error ? "#fff" : Utils.COLORS.RED_SECONDARY)};
	position: absolute;
	right: 0;
	margin: 10px;
	cursor: pointer;
	z-index: 3;
	@keyframes scale {
		0% {
			transform: scale(0.5);
		}
		100% {
			transform: scale(1.2);
		}
	}
	margin-top: 10px;
	/* margin-bottom: 5%; */
	animation: scale 3s infinite linear;
	user-select: none;
`;
export const Close = styled(AiOutlineCloseCircle)`
	width: ${(props) => props.width || "21px"};
	height: ${(props) => props.height || "21px"};
	color: #fff;
	position: absolute;
	right: 0;
	margin: 10px;
	cursor: pointer;
	z-index: 3;
	margin-top: 10px;
	/* margin-bottom: 5%; */
	user-select: none;
`;
export const Button = styled.button`
	background-color: ${(props) =>
		props.outline ? "rgb(241, 242, 243)" : Utils.COLORS.PRIMARY};
	border: none;
	color: ${(props) => (props.outline ? Utils.COLORS.PRIMARY : "white")};
	padding: 10px;
	border-radius: 10px;
	border: ${(props) =>
		props.outline ? "1px solid " + Utils.COLORS.PRIMARY : "none"};
	margin: 10px;
	cursor: pointer;
	&:hover {
		background-color: ${(props) =>
			props.outline ? "white" : Utils.COLORS.STRONG_PRIMARY};
	}
`;
export const BoxInfo = styled.div`
	position: absolute;
	display: ${(props) => (props.display ? "flex" : "none")};
	background-color: #145ca0de;
	width: 100vw;
	height: 100vh;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
export const TitleInfo = styled.h2`
	font-size: 25px;
	color: white;
`;
export const ErroInfo = styled.span`
	font-size: 16px;
	color: ${Utils.COLORS.RED_SECONDARY};
	background-color: white;
	padding: 20px;
	max-width: 500px;
`;

export const ErroInfoSub = styled.span`
	background-color: white;
	padding: 20px;
	max-width: 800px;
	color: #545454;
`;
