import produce from "immer";

const INITIAL_STATE = {
	lotes: [],
};

export default function lote(state = INITIAL_STATE, action) {
	switch (action.type) {
		case "@lote/ADD_LOTE_REQUEST":

			return produce(state, (draft) => {
				const product = { ...action.payload.lote_state };
				const input = action.payload.input;
			
				const items = state.lotes.filter(
					(item) =>
						item.codlocal === product.lote.CODLOCAL &&
						item.lote === product.lote.LOTE &&
						item.codprod === product.CODPROD
				);
			
				if (items.length) {
					draft.lotes.splice(0, draft.lotes.length);
					const old_lote = state.lotes;
			
					old_lote.map((lote) => {
						let qtd;
						if (
							lote.codlocal === product.lote.CODLOCAL &&
							lote.lote === product.lote.LOTE &&
							lote.codprod === product.CODPROD
						) {
							if (product.AGRUPMIN !== 1) {
								const value_qtd = input
									? parseFloat(product.AGRUPMIN)
									: parseFloat(product.quantidade);
								const qtd_lote = lote.quantidade;
								const qtd_sum = parseFloat(qtd_lote) + parseFloat(value_qtd);
								qtd = qtd_sum.toFixed(2);

							} else {
								const value_qtd = input ? 1 : parseFloat(product.quantidade);
								const qtd_lote = lote.quantidade;
								qtd = qtd_lote + value_qtd;
							}
						}
						return draft.lotes.push({
							codlocal: lote.codlocal,
							lote: lote.lote,
							codprod: lote.codprod,
							quantidade: qtd || lote.quantidade,
						});
					});
					// check_lote = true;
				} else {
					draft.lotes.push({
						codlocal: product.lote.CODLOCAL,
						lote: product.lote.LOTE,
						codprod: product.CODPROD,
						quantidade: product.quantidade,
					});
				}
			});
		case "@lote/REMOVE_LOTE_REQUEST":
			return produce(state, (draft) => {
				const product = { ...action.payload.lote_state };
				const input = action.payload.input;
				const items = state.lotes.filter(
					(item) =>
						item.codlocal === product.lote.CODLOCAL &&
						item.lote === product.lote.LOTE &&
						item.codprod === product.CODPROD &&
						item.quantidade > product.quantidade
				);
				if (items.length) {
					draft.lotes.splice(0, draft.lotes.length);
					const old_lote = state.lotes;
					old_lote.map((lote) => {
						let qtd;
						if (
							lote.codlocal === product.lote.CODLOCAL &&
							lote.lote === product.lote.LOTE &&
							lote.codprod === product.CODPROD &&
							lote.quantidade > product.quantidade
						) {
							if (product.AGRUPMIN > 1) {
								const value_qtd = input
									? parseFloat(product.AGRUPMIN)
									: parseFloat(product.quantidade);
								const qtd_lote = lote.quantidade;
								const qtd_sum = parseFloat(qtd_lote) - parseFloat(value_qtd);
								qtd = qtd_sum.toFixed(2);
							} else {
								const value_qtd = input ? 1 : parseFloat(product.quantidade);
								const qtd_lote = lote.quantidade;
								qtd = qtd_lote - value_qtd;
							}
						}
						return draft.lotes.push({
							codlocal: lote.codlocal,
							lote: lote.lote,
							codprod: lote.codprod,
							quantidade: qtd | lote.quantidade,
						});
					});
				} else {
					const item = state.lotes.findIndex((lote, index) => {
						return (
							lote.codlocal === product.lote.CODLOCAL &&
							lote.lote === product.lote.LOTE &&
							lote.codprod === product.CODPROD &&
							lote.quantidade === product.quantidade &&
							input === false
						);
					});
					if (item !== -1) {
						draft.lotes.splice(item, 1);
					}
				}
			});
		case "@lote/CLEAR_LOTE_REQUEST":
			return produce(state, (draft) => {
				draft.lotes = [];
			});
		case "@lote/UPDATE_CART_REQUEST":
			return produce(state, (draft) => {
				const newCart = [...action.payload.cart];
				draft.cart.splice(0, draft.cart.length);
				newCart.map((product) => draft.cart.push(product));
			});
		default:
			return state;
	}
}
