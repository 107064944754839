import { all, call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import api from "../../../service/api";
import { signInRequestFailure, signInRequestSucess } from "./actions";
import jwt_decode from "jwt-decode";

export function* signIn({ action, payload }) {
	try {
		const { email, password } = payload;

		const response = yield call(api.post, "auth", { email, password });

		const { token, name } = response.data;
		localStorage.setItem("name", name);

		api.defaults.headers.Authorization = `Bearer ${token}`;

		toast.success(`Bem-vindo, ${name}`);
		window.location.reload();
		yield put(signInRequestSucess(token));
	} catch (e) {
		toast.error("Error ao autenticar o usuário");
		yield put(signInRequestFailure());
	}
}
export function setToken({ payload }) {
	if (!payload) return;

	if (!payload.auth) return;

	const { token } = payload.auth;

	if (token) {
		const expires_date = jwt_decode(token).exp;
		const now = (Date.now() / 1000).toFixed(0);

		if (now >= expires_date) {
			window.localStorage.clear();
			window.location.href = "/";
		}

		api.defaults.headers.Authorization = `Bearer ${token}`;
	}
}

export default all([
	takeLatest("@auth/SIGN_IN_REQUEST", signIn),
	takeLatest("persist/REHYDRATE", setToken),
]);
