import produce from "immer";
import compare from "../../../helpers/compare";

const INITIAL_STATE = {
	cart: [],
};

export default function cart(state = INITIAL_STATE, action) {
	switch (action.type) {
		case "@cart/ADD_CART":
			return produce(state, (draft) => {
				const { cart } = action.payload

				const seq = Number(state.cart.length) + 1
				const newCart = JSON.parse(JSON.stringify(cart))
				newCart.seq = seq

				const state_cart = JSON.parse(JSON.stringify(state.cart))
				const sorted_cart = state_cart.sort((a,b) => compare('seq')(a,b))

				draft.cart = [...sorted_cart, newCart]
			});
		case "@cart/CLEAR_CART":
			return produce(state, (draft) => {
				draft.cart = []
			});
		case "@cart/REMOVE_ITEM":
			return produce(state, (draft) => {

				const { product } = action.payload
				
				const newCart = state.cart.filter(item => { 
					return !(item.codprod === product.codprod &&
					item.codemp === product.codemp &&
					item.codlocal === product.codlocal&&
					item.seq === product.seq)
				}) 
				
				const newCart2 = JSON.parse(JSON.stringify(newCart))

				let index = 1;
				const sorted_cart = newCart2.sort((a,b) => compare('seq')(a,b))
				const newSortedCart = sorted_cart.map(product => {
					product.seq = index
					index++
					return product
				})

				draft.cart = newSortedCart;

			});
		case "@cart/UPDATE_ITEM":
			return produce(state, (draft) => {

				const { product } = action.payload
				
				const newCart = state.cart.filter(item => { 
					return !(item.codprod === product.codprod &&
					item.ambient === product.ambient &&
					item.codemp === product.codemp &&
					item.seq === product.seq &&
					item.codlocal === product.codlocal)
				})

				const state_cart = JSON.parse(JSON.stringify(newCart))
				const state_product = JSON.parse(JSON.stringify(product))

				const sorted_cart = [...state_cart, state_product].sort((a,b) => compare('seq')(a,b))

				draft.cart = sorted_cart

			});
		default:
			return state;
	}
}
