import React from "react";
import { Container, Logo, Loader, LoadSVG } from "./styles";
import Loading from "../../assets/loading.svg";
import logo_img from "../../assets/logo-tanto-verde.png";

const SplashScreen = () => {
	return (
		<Container>
			<Loader>
				<Logo src={logo_img} />
				<LoadSVG type="image/svg" data={Loading}>
					<img src={Loading} alt="loading" />
				</LoadSVG>
			</Loader>
		</Container>
	);
};

export default SplashScreen;
