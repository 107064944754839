import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

::placeholder {
    color: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar{
		width: 3px;
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
	input.error{
		border-color: red !important;
	}

  *{
  font-size: 14px;
  outline: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: Roboto; */
  }

  body,html,#root {
    height: 100%;
  }

  body{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000000;
    background: #eee;
    position: relative !important;
		zoom: 100%;
  }

li{
  list-style: none;
}

// scrollbar
/* width */
::-webkit-scrollbar {
	width: 8px;
	max-width: 7px;
	max-height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d8d8d8; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
	border-radius: 5px;
  background: #009785; 
	max-width: 3px;
	max-height: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #009180; 
}
::-webkit-scrollbar-thumb:active {
	background: #07574e; 
}
`;
