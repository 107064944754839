import produce from "immer";

const INITIAL_STATE = {
	price: false,
};

export default function price(state = INITIAL_STATE, action) {
	switch (action.type) {
		case "@price/CHANGE_PRICE_STATE":
			return produce(state, (draft) => {
				draft.price = !state.price 
			});
		default:
			return state;
	}
}