import produce from 'immer'

const INITIAL_STATE = {
	menu_state: true,
	cart_state: false
}

export default function menu(state = INITIAL_STATE, action){
	switch(action.type){
		case '@menu/CHANGE_STATE':
			return produce(state, draft => {
        draft.menu_state = !state.menu_state;
      });
		case '@cart/CHANGE_STATE':
			return produce(state, draft => {
        draft.cart_state = !state.cart_state;
      });
		default:
			return state
	}
}