import { BiLoaderAlt } from "react-icons/bi";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	background-color: rgb(241, 242, 243);
`;

export const Loader = styled.div`
	display: flex;
	width: 100vw;
	height: 100vh;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const LoadSVG = styled.object`
	justify-content: center;
	background-color: transparent;
`;

export const Logo = styled.img`
	/* justify-content: center; */
	width: 220px;
	background-color: transparent;
	/* transition: opacity 0.3s, visibility 0.3s; */
	animation: fadeinout 1s infinite;
	@keyframes fadeinout {
		0% {
			opacity: 30%;
		}
		100% {
			opacity: 100%;
		}
	}
`;

export const Icon = styled(BiLoaderAlt)`
	width: ${(props) => props.width || "21px"};
	height: ${(props) => props.height || "21px"};
	@keyframes rotation {
		0% {
		}
		100% {
			transform: rotate(360deg);
		}
	}
	margin-top: 5%;
	margin-bottom: 5%;
	animation: rotation 1s infinite linear;
	user-select: none;
`;
